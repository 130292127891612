var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', [_c('b-row', {
    staticClass: "mb-1"
  }, [_c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('b-form-input', {
    staticClass: "d-inline-block mr-sm-1 mb-1 mb-sm-0",
    attrs: {
      "placeholder": "Search...",
      "type": "text"
    },
    on: {
      "keyup": _vm.onSearch
    },
    model: {
      value: _vm.searchTerm,
      callback: function callback($$v) {
        _vm.searchTerm = $$v;
      },
      expression: "searchTerm"
    }
  })], 1), _c('b-col', {
    staticClass: "d-flex justify-content-end align-items-center",
    attrs: {
      "lg": "8"
    }
  }, [_vm.$permissionAbility(_vm.ATTENDANCE_POLICY_CREATE, _vm.permissions) ? [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "flex-shrink-0",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.onAddShow(_vm.masterPolicy);
      }
    }
  }, [_vm._v(" Create Policy ")])] : _vm._e()], 2)], 1), _c('vue-good-table', {
    attrs: {
      "style-class": "vgt-table table-custom-style condensed striped",
      "mode": "remote",
      "total-rows": _vm.totalRecords,
      "is-loading": _vm.isLoading,
      "rows": _vm.rows,
      "sort-options": {
        enabled: false,
        multipleColumns: true,
        initialSortBy: [{
          field: 'id',
          type: 'desc'
        }]
      },
      "columns": _vm.columns,
      "pagination-options": {
        enabled: true,
        perPage: _vm.pageLength
      }
    },
    on: {
      "update:isLoading": function updateIsLoading($event) {
        _vm.isLoading = $event;
      },
      "update:is-loading": function updateIsLoading($event) {
        _vm.isLoading = $event;
      },
      "on-page-change": _vm.onPageChange,
      "on-sort-change": _vm.onSortChange,
      "on-column-filter": _vm.onColumnFilter,
      "on-per-page-change": _vm.onPerPageChange
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        var _props$row, _props$row2, _props$row3, _props$row4, _props$row5, _props$row6;
        return [props.column.field === 'formatted_check_in' ? _c('span', {
          staticClass: "font-weight-bold"
        }, [(_props$row = props.row) !== null && _props$row !== void 0 && _props$row.check_in ? [_vm._v(" " + _vm._s(_vm.formatFnTableCheckIn((_props$row2 = props.row) === null || _props$row2 === void 0 ? void 0 : _props$row2.check_in)) + " ")] : _vm._e()], 2) : _vm._e(), props.column.field === 'formatted_check_out' ? _c('span', {
          staticClass: "font-weight-bold"
        }, [(_props$row3 = props.row) !== null && _props$row3 !== void 0 && _props$row3.check_out ? [_vm._v(" " + _vm._s(_vm.formatFnTableCheckIn((_props$row4 = props.row) === null || _props$row4 === void 0 ? void 0 : _props$row4.check_out)) + " ")] : _vm._e()], 2) : _vm._e(), props.column.field === 'formatted_work_hours' ? _c('span', {
          staticClass: "font-weight-bold"
        }, [(_props$row5 = props.row) !== null && _props$row5 !== void 0 && _props$row5.work_hours ? [_vm._v(" " + _vm._s(_vm.formatFnWorkHour((_props$row6 = props.row) === null || _props$row6 === void 0 ? void 0 : _props$row6.work_hours)) + " ")] : _vm._e()], 2) : _vm._e(), props.column.field === 'action' ? _c('span', [_vm.$permissionAbility(_vm.ATTENDANCE_POLICY_EDIT, _vm.permissions) ? [_c('span', {
          on: {
            "click": function click($event) {
              return _vm.onShow(props.row);
            }
          }
        }, [_c('feather-icon', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "mr-50 custom-icon cursor-pointer",
          attrs: {
            "icon": "Edit2Icon",
            "title": "Edit",
            "size": "16"
          }
        })], 1)] : _vm._e(), _vm.$permissionAbility(_vm.ATTENDANCE_POLICY_DELETE, _vm.permissions) ? [_c('span', {
          on: {
            "click": function click($event) {
              return _vm.onDelete(props.row);
            }
          }
        }, [_c('feather-icon', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "mr-50 custom-icon cursor-pointer",
          attrs: {
            "icon": "TrashIcon",
            "title": "Delete",
            "size": "16"
          }
        })], 1)] : _vm._e()], 2) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }, {
      key: "pagination-bottom",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "d-flex justify-content-between flex-wrap"
        }, [_c('div', {
          staticClass: "d-flex align-items-center mb-0 mt-1"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" Showing 1 to ")]), _c('b-form-select', {
          staticClass: "mx-1",
          attrs: {
            "options": ['10', '25', '50', '100', '500']
          },
          on: {
            "input": function input(value) {
              return props.perPageChanged({
                currentPerPage: value
              });
            }
          },
          model: {
            value: _vm.pageLength,
            callback: function callback($$v) {
              _vm.pageLength = $$v;
            },
            expression: "pageLength"
          }
        }), _c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" of " + _vm._s(props.total) + " entries ")])], 1), _c('div', [_c('b-pagination', {
          staticClass: "mt-1 mb-0",
          attrs: {
            "value": 1,
            "total-rows": props.total,
            "per-page": _vm.pageLength,
            "first-number": "",
            "last-number": "",
            "align": "right",
            "prev-class": "prev-item",
            "next-class": "next-item"
          },
          on: {
            "input": function input(value) {
              return props.pageChanged({
                currentPage: value
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "prev-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronLeftIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }, {
            key: "next-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronRightIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }], null, true)
        })], 1)])];
      }
    }])
  }), _c('b-modal', {
    attrs: {
      "id": "modal-attendance-policy-remote-form",
      "centered": "",
      "title": _vm.modelType == 'editModel' ? 'Edit' : 'Create',
      "hide-footer": "",
      "no-close-on-backdrop": ""
    },
    on: {
      "hidden": _vm.hiddenModal
    }
  }, [_c('validation-observer', {
    ref: "remoteAttendancePolicyValidation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.validationForm($event);
      }
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label-for": "name"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "name",
      "vid": "name",
      "rules": "required|max:255"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "name",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Policy"
          },
          model: {
            value: _vm.name,
            callback: function callback($$v) {
              _vm.name = $$v;
            },
            expression: "name"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Check In*",
      "label-for": "check-in"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "check in",
      "vid": "check_in",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('flat-pickr', {
          staticClass: "form-control",
          attrs: {
            "id": "check-in",
            "placeholder": "09:00 AM",
            "config": {
              enableTime: true,
              noCalendar: true,
              static: true,
              dateFormat: 'H:i:S',
              altInput: true,
              altFormat: 'h:i K',
              minuteIncrement: 1
            },
            "state": errors.length > 0 ? false : null
          },
          model: {
            value: _vm.check_in,
            callback: function callback($$v) {
              _vm.check_in = $$v;
            },
            expression: "check_in"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Check Out*",
      "label-for": "check-out"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "check out",
      "vid": "check_out",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('flat-pickr', {
          staticClass: "form-control",
          attrs: {
            "id": "check-out",
            "placeholder": "06:00 PM",
            "config": {
              enableTime: true,
              noCalendar: true,
              static: true,
              dateFormat: 'H:i:S',
              altInput: true,
              altFormat: 'h:i K',
              minuteIncrement: 1
            },
            "state": errors.length > 0 ? false : null
          },
          model: {
            value: _vm.check_out,
            callback: function callback($$v) {
              _vm.check_out = $$v;
            },
            expression: "check_out"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Work Hours",
      "label-for": "work-hours"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "work hours",
      "vid": "work_hours",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('b-form-timepicker', {
          attrs: {
            "id": "work-hours",
            "locale": "en",
            "hour12": false,
            "reset-button": "",
            "state": errors.length > 0 ? false : null
          },
          model: {
            value: _vm.workHours,
            callback: function callback($$v) {
              _vm.workHours = $$v;
            },
            expression: "workHours"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _vm.enableDelay ? [_c('b-form-group', {
    attrs: {
      "label": "Delay Buffer (Min)",
      "label-for": "delay_buffer_minutes"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "delay_buffer_minutes",
      "vid": "delay_buffer_minutes",
      "rules": "integer"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var errors = _ref5.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "delay_buffer_minutes",
            "type": "number",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Delay Buffer Minutes"
          },
          model: {
            value: _vm.delayBufferMinutes,
            callback: function callback($$v) {
              _vm.delayBufferMinutes = $$v;
            },
            expression: "delayBufferMinutes"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 4174443993)
  })], 1)] : _vm._e(), _vm.enableExtremeDelay ? [_c('b-form-group', {
    attrs: {
      "label": "Extreme Delay Buffer (Min)",
      "label-for": "extreme_delay_buffer_minutes"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "extreme_delay_buffer_minutes",
      "vid": "extreme_delay_buffer_minutes",
      "rules": "integer"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var errors = _ref6.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "extreme_delay_buffer_minutes",
            "type": "number",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Extreme Delay Buffer Minutes"
          },
          model: {
            value: _vm.extremeDelayBufferMinutes,
            callback: function callback($$v) {
              _vm.extremeDelayBufferMinutes = $$v;
            },
            expression: "extremeDelayBufferMinutes"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 346745968)
  })], 1)] : _vm._e(), _c('b-form-checkbox', {
    staticClass: "custom-toggle",
    attrs: {
      "id": "customSwitchOverTime",
      "switch": ""
    },
    model: {
      value: _vm.enableOverTime,
      callback: function callback($$v) {
        _vm.enableOverTime = $$v;
      },
      expression: "enableOverTime"
    }
  }, [_vm._v(" Over Time ")]), _vm.enableOverTime ? [_c('b-form-group', {
    attrs: {
      "label": "Buffered Overtime (Min)",
      "label-for": "over_time_minutes"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "over_time_minutes",
      "vid": "over_time_minutes",
      "rules": "integer"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var errors = _ref7.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "over_time_minutes",
            "type": "number",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Over Time Minutes"
          },
          model: {
            value: _vm.overTimeMinutes,
            callback: function callback($$v) {
              _vm.overTimeMinutes = $$v;
            },
            expression: "overTimeMinutes"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 2890967447)
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Minimum Over Time (Min)",
      "label-for": "minimum_over_time_minutes"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "minimum_over_time_minutes",
      "vid": "minimum_over_time_minutes",
      "rules": "integer"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var errors = _ref8.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "minimum_over_time_minutes",
            "type": "number",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Minimum Over Time Minutes"
          },
          model: {
            value: _vm.minimumOverTimeMinutes,
            callback: function callback($$v) {
              _vm.minimumOverTimeMinutes = $$v;
            },
            expression: "minimumOverTimeMinutes"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 1737109758)
  })], 1)] : _vm._e(), _vm.isFormSubmitLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Submit ")])]], 2)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }